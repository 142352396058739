import RoleStore from './roleStore';
import TenantStore from './tenantStore';
import UserStore from './userStore';
import SessionStore from './sessionStore';
import AuthenticationStore from './authenticationStore';
import AccountStore from './accountStore';
import MarketDataStore from './marketDataStore';
import MarketOrderStore from './marketOrderStore';
import MarketTradeStore from './marketTradeStore';
import ReportEventLogStore from './reportEventLogStore';
import MarketNewsStore from './marketNewsStore';

export default function initializeStores() {
  return {
    authenticationStore: new AuthenticationStore(),
    roleStore: new RoleStore(),
    tenantStore: new TenantStore(),
    userStore: new UserStore(),
    sessionStore: new SessionStore(),
    accountStore: new AccountStore(),
    marketDataStore: new MarketDataStore(),
    marketOrderStore: new MarketOrderStore(),
    marketTradeStore: new MarketTradeStore(),
    reportEventLogStore: new ReportEventLogStore(),
    marketNewsStore: new MarketNewsStore(),
  };
}
