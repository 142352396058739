import * as React from 'react';

import { Route, Switch, Redirect } from 'react-router-dom';

import ProtectedRoute from './ProtectedRoute';
import utils from '../../utils/utils';
import { ROUTES } from '../../constants/routes';
import Logout from '../../scenes/Logout';

const Router = () => {
  const UserLayout = utils.getRoute(ROUTES.userBase).component;
  const AppLayout = utils.getRoute(ROUTES.appBase).component;

  return (
    <Switch>
      <Route path={ROUTES.userBase} render={(props: any) => <UserLayout {...props} />} />
      <ProtectedRoute
        path={ROUTES.appBase}
        render={(props: any) => <AppLayout {...props} exact />}
      />
      <Route path={ROUTES.logOut} render={(props: any) => <Logout />} />
      <Redirect from="/" to={ROUTES.realtimeTrading} exact />
    </Switch>
  );
};

export default Router;
