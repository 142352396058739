import http from '../httpService';
import { TransactionSummaryOutputDto } from '../stockOrder/dto/TransactionSummaryOutputDto';
import { CustomerOutputDto } from './dto/customerOutputDto';
import { PortfolioHoldingOutputDto } from './dto/PortfolioHoldingOutputDto';

class BackOfficeService {
  public async findCustomer(query: string): Promise<CustomerOutputDto[]> {
    let result = await http.get('api/services/app/BackOffice/GetCustomers', {
      params: { query: query },
    });

    return result.data.result;
  }

  public async getAccountBalance(cscsAccount: string): Promise<TransactionSummaryOutputDto> {
    let result = await http.get('api/services/app/Order/GetDashAccountBalance', {
      params: { cscsAccount: cscsAccount },
    });

    return result.data.result;
  }

  public async getDashAccountPortfolioHolding(
    cscsAccount: string
  ): Promise<PortfolioHoldingOutputDto[]> {
    let result = await http.get('api/services/app/BackOffice/GetDashAccountPortfolioHolding', {
      params: { cscsAcct: cscsAccount },
    });

    return result.data.result;
  }

  public async getDashAccountPortfolioValuation(cscsAccount: string): Promise<any> {
    let result = await http.get('api/services/app/BackOffice/GetDashAccountPortfolioValuation', {
      params: { cscsAcct: cscsAccount },
    });

    return result.data.result;
  }
}

export default new BackOfficeService();
