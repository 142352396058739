import React from 'react';
import AccountStore from '../stores/accountStore';
import AuthenticationStore from '../stores/authenticationStore';
import BackOfficeStore from '../stores/backOfficeStore';
import MarketDataStore from '../stores/marketDataStore';
import MarketNewsStore from '../stores/marketNewsStore';
import MarketOrderStore from '../stores/marketOrderStore';
import MarketTradeStore from '../stores/marketTradeStore';
import ReportEventLogStore from '../stores/reportEventLogStore';
import RoleStore from '../stores/roleStore';
import SessionStore from '../stores/sessionStore';
import StockOrderStore from '../stores/stockOrderStore';
import TenantStore from '../stores/tenantStore';
import UserStore from '../stores/userStore';

export const stores = Object.freeze({
  sessionStore: new SessionStore(),
  marketDataStore: new MarketDataStore(),
  accountStore: new AccountStore(),
  authenticationStore: new AuthenticationStore(),
  marketNewsStore: new MarketNewsStore(),
  marketOrderStore: new MarketOrderStore(),
  marketTradeStore: new MarketTradeStore(),
  reportEventLogStore: new ReportEventLogStore(),
  roleStore: new RoleStore(),
  tenantStore: new TenantStore(),
  userStore: new UserStore(),
  stockOrderStore: new StockOrderStore(),
  backOfficeStore: new BackOfficeStore(),
});

export const storesContext = React.createContext(stores);

export const StoresProvider = storesContext.Provider;
